<template>
  <div class="row m-auto col-md-12">
    <div class="col-md-3 mr-auto">
      <button
        v-if="id === '' && (secondTab || threeTab)"
        type="button"
        @click.prevent="
          getTab(tabs[indexSelect == 0 ? 0 : indexSelect - 1].tabName)
        "
        class="btn btn_transparent"
      >
        <img src="@/assets/image/icones/arrowBack.svg" alt="Voltar" />Voltar
      </button>
      <button
        v-if="id !== ''"
        type="button"
        class="btn btn_delete"
        :disabled="disabled"
        @click.prevent="$emit('excluirTenant')"
      >
        Excluir Unidade
      </button>
    </div>
    <div class="col-md-5 ml-auto">
      <div class="row">
        <div class="col-md-3 m-auto" v-show="threeTab || id != ''">
          <button
            type="button"
            class="btn btn_secondary"
            @click.prevent="$emit('closeModal')"
          >
            Cancelar
          </button>
        </div>
        <div class="col-md-4 m-auto">
          <button
            type="button"
            :disabled="disabled"
            @click.prevent="$emit('sendUnidade')"
            class="btn btn_default"
            v-if="threeTab || id != ''"
          >
            {{ id != "" ? "Salvar" : "Criar" }}
          </button>
          <button
            class="btn btn_secondary"
            @click.prevent="
              getTab(
                tabs[indexSelect == tabs.length ? tabs.length : indexSelect + 1]
                  .tabName
              )
            "
            v-if="(firstTab || secondTab) && id == ''"
          >
            Proximo
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Create -->
</template>

<script>
export default {
  props: ["id", "tabs", "disabled"],
  data() {
    return {
      indexSelect: 0,
      load: false,
      firstTab: true,
      secondTab: false,
      threeTab: false
    };
  },
  methods: {
    showTabCreate(index) {
      this.indexSelect = index;
      switch (index) {
        case 0:
          this.firstTab = true;
          this.secondTab = false;
          this.threeTab = false;
          break;
        case 1:
          this.firstTab = false;
          this.secondTab = true;
          this.threeTab = false;
          break;
        case 2:
          this.firstTab = false;
          this.secondTab = false;
          this.threeTab = true;
          break;
      }
    },
    getTab(tabName) {
      document.getElementById(`${tabName}-tab`).click();
    }
  }
};
</script>

<style scoped src="@/assets/style/modal.css"></style>
