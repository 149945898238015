<template>
  <div
    class="tab-pane fade col-md-12"
    id="tenantDispatcher"
    role="tabpanel"
    aria-labelledby="tenantDispatcher-tab`"
  >
    <div class="row">
      <div
        tag="div"
        class="col-md-7 mb-1 form-group form-outline"
        name="Despachante"
      >
        <label class="mb-1 label_default">Despachante</label>
        <div class="input-group">
          <select
            class="form-control input_default"
            :value="myTenantDispatcher"
            @change="
              $emit('update:myTenantDispatcher', parseInt($event.target.value))
            "
          >
            <option :value="''" />
            <option
              :value="item.id"
              v-for="(item, index) in tenants"
              :key="index"
              :style="item.isDispatcher ? 'font-weight: bold;' : ''"
              >{{ item.name }}
              <strong>{{ item.isDispatcher ? " - Despachante" : "" }}</strong>
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-12 mb-1 form-group form-outline">
        <label class="label_default label_blue">
          Unidade Despachante
        </label>
      </div>
      <div class="col-md-12 row">
        <div class="col-md-auto">
          <div class="d-flex align-items-center form-check">
            <div class="mr-2 field-checkbox">
              <input
                class="field"
                type="checkbox"
                id="noDispatcher"
                :checked="!isDispatcher"
                :value="!isDispatcher"
                @change="
                  $emit(
                    'update:isDispatcher',
                    $event.target.checked ? false : true
                  )
                "
              />
              <label class="description" for="noDispatcher"></label>
            </div>
            <p
              class="label_default _checkbox mb-2 list"
              @click="$emit('getCheckBox', $event.target)"
            >
              Não
            </p>
          </div>
        </div>
        <div class="col-md-auto">
          <div class="d-flex align-items-center form-check">
            <div class="mr-2 field-checkbox">
              <input
                class="field"
                type="checkbox"
                id="dispatcher"
                :checked="isDispatcher"
                :value="isDispatcher"
                @change="
                  $emit(
                    'update:isDispatcher',
                    $event.target.checked ? true : false
                  )
                "
              />
              <label class="description" for="dispatcher"></label>
            </div>
            <p
              class="label_default _checkbox mb-2 list"
              @click="$emit('getCheckBox', $event.target)"
            >
              Sim
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["isDispatcher", "tenants", "myTenantDispatcher"]
};
</script>

<style scoped src="@/assets/style/modal.css"></style>
