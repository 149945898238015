<template>
  <modal
    :modalId="`modalUnidade`"
    :modalTitle="id === '' ? 'Criar Unidade' : 'Editar Unidade'"
    :load="load"
    :typeLg="`modal_lg`"
    @closeModal="closeModal"
  >
    <ValidationObserver ref="formUnidade" class="row" tag="form">
      <div class="tab-content" id="myTabContent">
        <tabs :tabs="tabList" @tabsMovie="tabsMovie" />

        <tenantInformations
          class="conteudo"
          :class="`show active`"
          :id.sync="id"
          :name.sync="superObj.name"
          :reference.sync="superObj.reference"
          :userIds.sync="superObj.userIds"
          :state.sync="superObj.state"
          :city.sync="superObj.city"
          :openingDate.sync="superObj.openingDate"
          :address.sync="superObj.address"
          :numberAddress.sync="superObj.numberAddress"
          :district.sync="superObj.district"
          :email.sync="superObj.email"
          :cellphone.sync="superObj.cellphone"
          :hasPublicityCard.sync="superObj.hasPublicityCard"
          :hasOwnStore.sync="superObj.hasOwnStore"
          :socialMedia.sync="superObj.socialMedia"
          :listUsers="listUser"
          @getCheckBox="getCheckBox"
          ref="tenantinformations"
        />

        <tenantServices
          class="conteudo"
          :services.sync="superObj.services"
          @getCheckBox="getCheckBox"
          ref="tenantServices"
        />

        <tenantGoals
          class="conteudo"
          :port.sync="superObj.port"
          :goal.sync="superObj.goal"
          :goalDuration.sync="superObj.goalDuration"
          ref="tenantGoals"
        />

        <tenantDispatcher
          class="conteudo"
          :isDispatcher.sync="superObj.isDispatcher"
          :myTenantDispatcher.sync="superObj.myTenantDispatcher"
          :tenants="tenantsActives"
          ref="tenantDispatcher"
        />

        <actions
          :id="superObj.id"
          :tabs="tabList"
          :disabled.sync="disabled"
          @closeModal="closeModal"
          @sendUnidade="sendUnidade"
          @excluirTenant="excluirTenant"
          ref="actions"
        />
      </div>
    </ValidationObserver>
  </modal>
</template>

<script>
import { HubConnectionBuilder } from "@aspnet/signalr";
import { extend } from "vee-validate";
import HTTP from "@/api_system";
import toast from "./unidades/toast.vue";
import tabs from "@/components/tabs/modalTabs.vue";
import tenantInformations from "./unidades/tabs/tenantInformations.vue";
import tenantServices from "./unidades/tabs/tenantServices.vue";
import tenantGoals from "./unidades/tabs/tenantGoals.vue";
import tenantDispatcher from "./unidades/tabs/tenantDispatcher.vue";
import actions from "./unidades/acoes/botoes_rodape.vue";
import modal from "../../../modal/modalDefault.vue";
export default {
  components: {
    toast,
    tabs,
    tenantInformations,
    tenantServices,
    tenantGoals,
    tenantDispatcher,
    actions,
    modal
  },
  props: ["listUser", "id", "tenantDisableds", "tenantsActives"],
  data() {
    return {
      superObj: {
        id: "",
        name: "",
        reference: "",
        userIds: "",
        state: "",
        city: "",
        openingDate: "",
        address: "",
        numberAddress: "",
        district: "",
        email: "",
        cellphone: "",
        hasPublicityCard: false,
        mediaSocial: "",
        hasOwnStore: false,
        services: [],
        port: "",
        goal: "",
        goalDuration: ""
      },
      disabled: false,
      load: true,
      toastShow: true,
      tabList: [
        {
          description: "Dados",
          tabName: "tenantinformation"
        },
        {
          description: "Categorias e Servicos",
          tabName: "tenantservice"
        },
        {
          description: "Metas",
          tabName: "tenantgoal"
        },
        {
          description: "Despachante",
          tabName: "tenantDispatcher"
        }
      ]
    };
  },
  mounted() {
    this.registerValidations();
    if (this.id != "") {
      this.getUnidadeForEdit();
    } else {
      this.load = false;
    }
    this.getScripts();
  },
  methods: {
    getScripts() {
      var self = this;
      $("#modalUnidade").on("hide.bs.modal", function(e) {
        self.$emit("update:id", "");
        Object.keys(self.superObj).forEach(key => {
          switch (typeof self.superObj[key]) {
            case Array:
              self.superObj[key] = [];
              break;
            default:
              self.superObj[key] = "";
              break;
          }
          //  Object.assign(self.superObj[key], initialState[key]);
        });
      });
    },
    sendUnidade() {
      this.$refs.formUnidade.validate().then(success => {
        if (success) {
          const delay = new Promise(resolve => setTimeout(resolve, 2000));
          this.$refs.tenantServices.convertArray();
          this.disabled = true;
          this.$nextTick(() => {
            var url;
            var msg;
            if (this.id) {
              url = "services/app/Tenant/EditTenant";
              this.superObj.id = this.id;
              this.$store.commit("editeChange", true);
              msg = "Solicitação de edição realizada com sucesso, aguarde!";
            } else {
              url = "services/app/Tenant/CreateTenant";
              this.$store.commit("editeChange", false);
              msg = "Solicitação de criação realizada com sucesso, aguarde!";
            }
            this.toast(msg, this.$toast.success);
            delay
              .then(t => {
                HTTP.post(url, this.superObj, {
                  headers: {
                    Authorization: `Bearer ${this.$store.getters.getterToken}`
                  }
                })
                  .then(response => {
                    let { result } = response.data;
                    this.signalCreate(result);
                    this.disabled = false;
                    this.closeModal();
                  })
                  .catch(error => {
                    this.disabled = false;
                    this.toast(
                      error.response.data.error.message,
                      this.$toast.error
                    );
                  });
              })
              .catch(error => {
                this.disabled = false;
                this.toast(
                  error.response.data.error.message,
                  this.$toast.error
                );
              });
          });
        } else {
          this.getTabFailed();
        }
      });
    },
    excluirTenant() {
      this.disabled = true;
      HTTP.delete(`services/app/Tenant/Delete?Id=${this.id}`, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getterToken}`
        }
      })
        .then(response => {
          this.toast("Unidade excluida", this.$toast.error);
          this.$nextTick(() => {
            this.$emit("getUnidades");
            this.closeModal();
            this.disabled = false;
          });
        })
        .catch(error => {
          this.toast("Ops algo deu errado", this.$toast.error);
          this.disabled = false;
        });
    },
    getTabFailed() {
      var elements = document.getElementsByClassName("failed");
      if (elements.length) {
        var arrayElements = [...elements];
        var selected = arrayElements[0];
        var found = false;
        while (!found) {
          if (selected.parentNode.id === "") {
            selected = selected.parentNode;
          } else {
            selected = document.getElementById(`${selected.parentNode.id}-tab`);
            if (selected != undefined) {
              selected.click();
              found = true;
            }
          }
        }
      }
    },
    tabsMovie(index) {
      this.$refs.actions.showTabCreate(index);
    },
    getCheckBox(event) {
      event.parentNode.childNodes[0].childNodes[1].click();
    },
    signalCreate(uid) {
      const connection = new HubConnectionBuilder()
        .withUrl(`${process.env.VUE_APP_ROOT_API}/Jobsignalr`)
        .configureLogging(signalR.LogLevel.Information)
        .build();
      connection.serverTimeoutInMilliseconds = 120000;
      this.$toast.clear();
      this.showToast(false);
      connection.on("progress", percent => {
        if (percent === 0.1) {
          this.$toast.clear();
          this.toast("Algo deu errado", this.$toast.error);
          connection.stop();
          return;
        }
        if (percent === 100) {
          if (this.$store.getters.editGet) {
            this.$store.commit(
              "progressChange",
              `Unidade Editada com Sucesso!`
            );
          } else {
            this.$store.commit("progressChange", `Unidade criada com Sucesso!`);
          }
          this.$toast.clear();
          this.toast(toast, this.$toast.success);
          this.$listeners.getUnidades();
          connection.stop();
        } else {
          this.$store.commit("progressChange", percent);
        }
      });
      connection
        .start()
        .then(() => {
          connection.invoke("AssociateJob", uid);
          this.$store.commit("progressChange", 0);
        })
        .catch(err => {
          console.error(err.toString());
          this.toast("Algo deu errado", this.$toast.error);
        });
    },
    showToast(timeShow) {
      this.$toast.info(toast, {
        position: "bottom-right",
        timeout: timeShow,
        closeOnClick: false,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        draggable: false,
        draggablePercent: 0.6,
        showCloseButtonOnHover: true,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        rtl: false
      });
    },
    registerValidations() {
      extend("validaName", {
        validate: this.validaName,
        message: (field, params, data) => {
          return "Esse nome já esta sendo usado por outra unidade";
        }
      });
      extend("validaOldname", {
        validate: this.validaOldname,
        message: (field, params, data) => {
          return "Esse nome está indisponivel";
        }
      });
    },
    getUnidadeForEdit() {
      HTTP.get(`services/app/Tenant/GetTenantForEdit?id=${this.id}`, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getterToken}`
        }
      }).then(response => {
        this.superObj = response.data.result;
        this.superObj.id = this.id;
        if (this.superObj.openingDate === "0001-01-01T00:00:00") {
          this.superObj.openingDate = "";
        } else {
          this.superObj.openingDate = this.superObj.openingDate.split("T")[0];
        }
        this.load = false;
      });
    },
    validaName() {
      var validaName = this.tenantsActives.some(
        e =>
          (e.tenancyName.toLowerCase().trim() ==
            this.superObj.name.toLowerCase().trim() ||
            e.name.toLowerCase().trim() ==
              this.superObj.name.toLowerCase().trim()) &&
          e.id != this.superObj.id
      );

      return !validaName;
    },
    validaOldname() {
      var desative = this.tenantDisableds.some(
        e =>
          (e.tenancyName.toLowerCase().trim() ==
            this.superObj.name.toLowerCase().trim() ||
            e.name.toLowerCase().trim() ==
              this.superObj.name.toLowerCase().trim()) &&
          e.id != this.superObj.id
      );
      return !desative;
    },
    closeModal() {
      this.$emit("closeModal");
    }
  }
};
</script>
<style scoped src="@/assets/style/modal.css"></style>
<style scoped>
@media (max-width: 230px) {
  #myTabContent {
    max-width: 200;
    overflow-x: auto;
    min-width: 115px;
  }
}

#myTabContent {
  width: 100%;
}

.conteudo {
  min-height: 450px;
  padding-top: 12px;
}
</style>
