<template>
  <div class="col-md-12 p-0">
    <div class="input-group" style="flex-direction:row-reverse">
      <div class="input-group-prepend">
        <button
          class="btn btn-outline-light btn-sm"
          @click="EnableInput()"
          type="button"
        >
          <img src="@/assets/image/icones/search.svg" />
        </button>
      </div>
      <input
        :id="id"
        type="text"
        @keyup="filterCp($event)"
        class="form-control search-false"
        placeholder="Digite para pesquisar"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: ["id"],
  data() {
    return {};
  },
  methods: {
    filterCp(event) {
      this.$emit("filterCp", event);
    },
    EnableInput() {
      if ($(`#${this.id}`).hasClass("search-false")) {
        $(`#${this.id}`).removeClass("search-false");
        $(`#${this.id}`).addClass("search-true");
      } else {
        $(`#${this.id}`).addClass("search-false");
        $(`#${this.id}`).removeClass("search-true");
      }
    }
  }
};
</script>

<style scoped>
.search-false {
  visibility: hidden;
  opacity: 0;
}
.search-true {
  visibility: visible;
  opacity: 1;
  transition: visibility 5s, opacity 1s linear;
}

.btn-outline-light,
.btn-outline-light:hover,
.btn-outline-light:active,
.btn.focus,
.btn:focus-visible,
.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  background: #2D7CB8 !important;
}
</style>
